import { createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from 'config';
import axios from 'utils/axios';
import { dispatch } from '../index';
import { NumberLocale } from 'yup/lib/locale';

const initialState: any = {
  courses: [],
  totalCount: 0,
  totalPages: 1,
  page: 1
};

const courses = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setCourses(state, action) {
      state.courses = action.payload;
    },
    setTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setTotalPages(state, action) {
      state.totalPages = action.payload;
    },
    reset: () => initialState
  }
});

export const { setCourses, setPage, setTotalCount, setTotalPages } = courses.actions;
export default courses.reducer;

export function getCourses({ dataLimit, page }: { dataLimit: number; page: number }) {
  return async () => {
    try {
      const response = await axios.get(`${BASE_URL}/course/admin/all?skip=0&limit=${dataLimit}&page=${page}`);
      dispatch(courses.actions.setCourses(response.data.courses || []));
      dispatch(courses.actions.setTotalCount(response.data.totalCount || 0));
      dispatch(courses.actions.setTotalPages(response.data.totalPages || 1));
      dispatch(courses.actions.setPage(response.data.page || 1));
    } catch (error) {
      console.log(error);
    }
  };
}
