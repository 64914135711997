import React, { useEffect, useState } from 'react';
import { InputLabel, Stack } from '@mui/material';
import BarChart from './BarChart';
import { dailyacitivitycolumns } from './constant';
import BasicTable from '../../components/react-table/BasicTable';
import axios from 'utils/axios';
import { BASE_URL } from 'config';
import moment from 'moment';
import { Button } from '@mui/material';
import Loader from 'components/Loader';
const bgColor = {
  backgroundColor: '#ECEFFF',
  color: 'blue',
  borderRadius: '10px',
  // maxWidth: '250px',
  paddingLeft: '10px'
};
const bgColorselected = {
  background: 'linear-gradient(103.4deg, #2D00D2 12.04%, #2C00D3 30.87%, #3700C8 46.11%, #3E00C0 63.54%, #4B00B4 80.82%, #5400AA 93.76%)',
  color: ' #FFFFFF',
  borderRadius: '10px',
  // maxWidth: '250px',
  paddingLeft: '10px'
};
const DailyActivitySubscription = ({ dailyactivity, startDate, endDate }: any) => {
  const [graphdata, setgraphdata] = useState({});
  const [dailyactivitydata, setdailyactivitydata] = useState([]);
  const [tabledata, settabledata] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [queryparams, setqueryparams] = useState('all');
  const [isExporting, setIsExporting] = useState(false);
  const [exportdata, setexportdata] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const handlePageChange = (pages: number) => {
    setPage(pages);
  };
  const init = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/admin/subscriptiondailyreport/${queryparams}`);
      if (response.data) {
        setdailyactivitydata(response.data || []);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setIsExporting(false);
    }
  };
  useEffect(() => {
    if (dailyactivity) {
      init();
    }
  }, [dailyactivity, queryparams]);
  useEffect(() => {
    if (dailyactivitydata?.length > 0) {
      const formattedStart = moment(startDate || moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')
        .startOf('day')
        .toISOString();

      const formattedEnd = moment(endDate || moment().format('DD-MM-YYYY'), 'DD-MM-YYYY')
        .endOf('day')
        .toISOString();
      const Subscriptiondata = dailyactivitydata?.filter((item: any) => {
        let itemDate = '';
        if (item?.date) {
          // Assume item.date is in "DD-MM-YYYY" format
          itemDate = moment(item?.date, 'DD-MM-YYYY').toISOString();
        }
        return itemDate >= formattedStart && itemDate <= formattedEnd;
      });
      setgraphdata({
        labels: Subscriptiondata?.map((data: any) => data.date),
        datasets: [
          {
            label: 'Dataset',
            data: Subscriptiondata?.map((data: any) => data.total),
            fill: false,
            borderColor: 'rgba(75,192,192,1)',
            tension: 0.1
          }
        ]
      });
      settabledata(Subscriptiondata);
      const exportingdata = Subscriptiondata.map((data: any) => {
        return {
          Date: data.date,
          ExistingSubscription: data.existing,
          ActiveSubscription: data.active.length,
          ExipredSubscription: data.expired.length,
          TotalSubscription: data.total
        };
      });
      setexportdata(exportingdata);
    }
  }, [dailyactivitydata, startDate, endDate]);
  const handleExportClick = () => {
    setIsExporting(true);
    function convertJsonToCsv(jsonData: any) {
      // Get headers from the first object in the array
      const headers = Object.keys(jsonData[0]);

      // Create a CSV string with headers as the first row
      let csvString = headers.join(',') + '\n';

      // Loop through the array and create a new row for each object
      for (const obj of jsonData) {
        const values = [];

        // Loop through the keys in the object and add them to the row
        for (const key of headers) {
          values.push(obj[key]);
        }

        // Add the row to the CSV string
        csvString += values.join(',') + '\n';
      }

      return csvString;
    }

    function downloadCsvFile(csvString: any, filename: any) {
      const link = document.createElement('a');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    const csvString = convertJsonToCsv(exportdata);
    downloadCsvFile(csvString, 'data.csv');
  };
  return (
    <>
      {(!tabledata || loading) && <Loader />}
      <Stack direction={'row'} spacing={2}>
        <Button
          sx={queryparams === '66abb219fce2bb07607a19f8' ? bgColorselected : bgColor}
          onClick={() => setqueryparams('66abb219fce2bb07607a19f8')}
        >
          {`Equity SPL Trades`}
        </Button>
        <Button
          sx={queryparams === '640ad224ff5b887e32ea1b29' ? bgColorselected : bgColor}
          onClick={() => setqueryparams('640ad224ff5b887e32ea1b29')}
        >
          {`Stock Options`}
        </Button>
        <Button
          sx={queryparams === '63b2a525b6079ecb3c76eaca' ? bgColorselected : bgColor}
          onClick={() => setqueryparams('63b2a525b6079ecb3c76eaca')}
        >
          {`Momentum Futures`}
        </Button>
        <Button
          sx={queryparams === '6423fc83286785e5293b61f3' ? bgColorselected : bgColor}
          onClick={() => setqueryparams('6423fc83286785e5293b61f3')}
        >
          {`Index Options`}
        </Button>
        <Button
          sx={queryparams === '64295821032fae9f38d52fe4' ? bgColorselected : bgColor}
          onClick={() => setqueryparams('64295821032fae9f38d52fe4')}
        >
          {`Options Combo`}
        </Button>
        <Button
          sx={queryparams === '6700fc4bc43fd2e8d56b0b05' ? bgColorselected : bgColor}
          onClick={() => setqueryparams('6700fc4bc43fd2e8d56b0b05')}
        >
          {`Index Spreads`}
        </Button>
        <Button
          sx={queryparams === '6700fd85c43fd2e8d56b0d93' ? bgColorselected : bgColor}
          onClick={() => setqueryparams('6700fd85c43fd2e8d56b0d93')}
        >
          {`Stock Spreads`}
        </Button>
      </Stack>
      <Stack direction={'column'} gap={2}>
        <Stack
          width={'100%'}
          borderRadius={5}
          bgcolor={'white'}
          boxShadow={'rgba(149, 157, 165, 0.2) 0px 8px 24px'}
          alignItems={'center'}
          gap={2}
        >
          <Stack width={'80%'} height={'45vh'} pr={7} pl={7}>
            {Object.keys(graphdata).length > 0 && (
              <BarChart barChartData={graphdata} startDate={startDate} endDate={endDate} title={'Subscription Daily'} charttype={'line'} />
            )}
          </Stack>
        </Stack>
        <Stack
          width={'100%'}
          borderRadius={5}
          bgcolor={'white'}
          boxShadow={'rgba(149, 157, 165, 0.2) 0px 8px 24px'}
          alignItems={'center'}
          p={5}
        >
          <Stack direction="row" spacing={2} sx={{ position: 'absolute', right: 100 }}>
            <Button sx={bgColor} onClick={handleExportClick} disabled={isExporting}>
              {isExporting ? 'Exporting...' : 'Export CSV'}
            </Button>
          </Stack>
          <BasicTable columns={dailyacitivitycolumns} data={tabledata} striped currentPage={page} onPageChange={handlePageChange} />
        </Stack>
      </Stack>
    </>
  );
};

export default DailyActivitySubscription;
